<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import Ripple from "vue-ripple-directive";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  data(){
    return {
    }
  },
  created() {
    
    var _g = this,
      g = this;
    var i = setInterval(() => {
      var user = localStorage.getItem("user");
      if (!user) {
        if (
          window.location.pathname != "/login" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/register" &&
          !window.location.pathname.includes("autologin") &&
          window.location.pathname != "/error-404"
        ) {
          _g.$router.push("/login");
        }
      } else {
        clearInterval(i);
        $.post(api + "/user/auth/check", {
          jwt: JSON.parse(user).jwt,
        })
          .then(function (data) {
            if (data.status == 100) {
              if (
                JSON.stringify(data.response) != localStorage.getItem("user")
              ) {
                location.reload();
              } else {
                var pp = setInterval(function(){
                  if(!data.response.pers){
                    clearInterval(pp)
                  }else{
                    if(window.location.pathname.includes("students") && !data.response.pers.includes("students") && !data.response.pers.includes("*")){
                      clearInterval(pp)
                      window.location = '/'
                    }
                    if(window.location.pathname.includes("admins") && !data.response.pers.includes("admins") && !data.response.pers.includes("*")){
                      clearInterval(pp)
                      window.location = '/'
                    }
                    if(window.location.pathname.includes("settings") && !data.response.pers.includes("settings") && !data.response.pers.includes("*")){
                      clearInterval(pp)
                      window.location = '/'
                    }
                    if(window.location.pathname.includes("balance") && !data.response.pers.includes("balance") && !data.response.pers.includes("*")){
                      clearInterval(pp)
                      window.location = '/'
                    }
                    if(window.location.pathname.includes("barcode") && !data.response.pers.includes("barcode") && !data.response.pers.includes("*")){
                      clearInterval(pp)
                      window.location = '/'
                    }
                  }
                }, 10)
                if(!data.response.activated && !data.response.pers && window.location.pathname != '/activate'){
                  window.location = '/activate'
                }
              }
              localStorage.setItem("user", JSON.stringify(data.response));
            } else {
              _g.$router.push("/logout");
            }
          })
          .catch(function (f) {
            console.log(f)
            _g.$router.push("/logout");
          });
      }
    }, 100);
  },
  methods: {
    done(){
    }
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    // Layouts
    BModal,BFormCheckbox,
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
